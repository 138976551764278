<template>
  <div class="web-box">
    <transition name="fade">
      <div v-show="isShow" class="box">
        <Top />
      </div>
    </transition>
    <AppMain />
    <BackTop />
    <Bottom />
  </div>
</template>

<script>
import Top from '@/components/Top';
import Bottom from '@/components/Bottom';
import BackTop from '@/components/BackTop';
import AppMain from './components/AppMain.vue';

export default {
  name: 'Layout',
  components: {
    Top,
    AppMain,
    Bottom,
    BackTop
  },
  data() {
    return {
      scrollTop: 0,
      isShow: true
    };
  },
  computed: {
    key() {
      const { fullPath } = this.$route;
      return fullPath;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      // 页面滚动距顶部距离
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      const scroll = scrollTop - this.scrollTop;
      // console.log(scroll);
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.isShow = true;
      } else if (scroll > 0) {
        if (scrollTop > 100) {
          this.isShow = false;
        }
      } else {
        return;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
.web-box {
  padding-top: 84px;
  min-width: 1300px;
}
.box {
  display: flex;
  opacity: 1;
  width: 100%;
  height: 84px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,61,255, 0.8);
  z-index: 1000;
  box-shadow: 0px 0px 6px 0px rgba(139,154,183,0.20);
  .logo {
    width: 100px;
    margin: 0 156px 0 90px;
    img {
      width: 100%;
      z-index: 1000;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateY(-100%);
}
@media screen and  ( max-width: 1500px ) {
  .logo {
    display: none;
  }
}
</style>
