<template>
  <div class="top-nav">
    <div class="logo" @click="$router.push('/')">
      <img :src="require('@/assets/img/logo.png')" alt="logo">
    </div>
    <ul class="nav-list">
      <li
        v-for="item in menuItems"
        :key="item.id"
        class="nav-item"
        :class="{'has-child': item.child.length, 'active': isActiveMainItem(item)}"
        :style="{ marginLeft: item.marginLeft }"
        @mouseenter="showSubmenu(item)"
        @mouseleave="hideSubmenu(item)"
        @click="navigate(item)"
      >
        <div class="nav-link">{{ item.name }}</div>
        <ul v-if="item.child.length && item.name !== '新闻资讯'" class="submenu">
          <li
            v-for="child in item.child"
            :key="child.id"
            class="submenu-item"
            :class="{'active': isActiveSubItem(child)}"
            @mouseenter="child.showSubmenu = true"
            @mouseleave="child.showSubmenu = false"
            @click.stop="navigate(child)"
          >
            <div class="submenu-link">
              {{ child.name }}
            </div>
            <!-- 二级子菜单 -->
            <ul
              v-if="child.child && child.child.length && child.showSubmenu"
              class="submenu-child"
            >
              <li
                v-for="sub in child.child"
                :key="sub.id"
                class="submenu-child-item"
                :class="{
                  'active': isActiveSubItem(sub)
                }"
                @click.stop="navigate(sub)"
              >
                <div class="submenu-child-link">
                  {{ sub.name }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div class="call">0571—82650015</div>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  data() {
    return {
      menuItems: [],
      currentPath: this.$route.path
    };
  },
  async created() {
    this.menuItems = await this.getMenuItems();
  },
  methods: {
    async getMenuItems() {
      const data = [
        {
          'desction': '首页',
          'name': '首页',
          'pathUrl': '/',
          'id': 1,
          'child': []
        },
        {
          'desction': '智慧病房',
          'name': '智慧病房',
          'pathUrl': '/smartWard',
          'id': 49,
          'child': [
            {
              'desction': '',
              'name': '全部',
              'pathUrl': '/allSolutions',
              'id': 499,
              'child': []
            },
            {
              'desction': '',
              'name': '智慧医疗',
              'pathUrl': '/smartWard',
              'id': 50,
              'child': [
                // 示例：假设“病房智能交互系统”下面还有二级子菜单
                {
                  'desction': '',
                  'name': '移动护理系统',
                  'pathUrl': '/smartWard/mobileNursing',
                  'id': 107,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '智慧病房整体解决方案',
                  'pathUrl': '/smartWard/ward',
                  'id': 101,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '护士站智能交互系统',
                  'pathUrl': '/smartWard/nurseStation',
                  'id': 102,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '病房智能交互系统',
                  'pathUrl': '/smartWard/interaction',
                  'id': 103,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '床旁智能交互系统',
                  'pathUrl': '/smartWard/besideBed',
                  'id': 104,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '床位监测管理系统',
                  'pathUrl': '/smartWard/bedMonitor',
                  'id': 105,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '智能输液监控系统',
                  'pathUrl': '/smartWard/infusion',
                  'id': 106,
                  'child': []
                }
              ]
            },
            {
              'desction': '',
              'name': '智慧服务',
              'pathUrl': '/smartWard/bedside',
              'id': 51,
              'child': [
                {
                  'desction': '',
                  'name': '互联网+护理系统',
                  'pathUrl': '/smartWard/netNursing',
                  'id': 201,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '满意度调查系统',
                  'pathUrl': '/smartWard/satisfaction',
                  'id': 202,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '互联网医院',
                  'pathUrl': '/smartWard/netHospital',
                  'id': 203,
                  'child': []
                }
              ]
            },
            {
              'desction': '',
              'name': '智慧管理',
              'pathUrl': '/smartWard/nurse',
              'id': 52,
              'child': [
                {
                  'desction': '',
                  'name': '护理管理系统',
                  'pathUrl': '/smartWard/nursing',
                  'id': 303,
                  'child': []
                },
                {
                  'desction': '',
                  'name': 'HRP',
                  'pathUrl': '/smartWard/hrp',
                  'id': 301,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '院内感染管理系统',
                  'pathUrl': '/smartWard/infect',
                  'id': 302,
                  'child': []
                },
                {
                  'desction': '',
                  'name': '智能终端运行保障系统',
                  'pathUrl': '/smartWard/support',
                  'id': 304,
                  'child': []
                }
              ]
            }
          ]
        },
        // ... 其他菜单项 ...
        {
          'desction': '',
          'name': '区域肿瘤/慢病防治平台',
          'pathUrl': '/platform',
          'id': 53,
          'child': [
            {
              'desction': '',
              'name': '区域肿瘤防治系统',
              'pathUrl': '/platform/tumor',
              'id': 55,
              'child': []
            },
            {
              'desction': '',
              'name': '区域专病/慢病管理平台',
              'pathUrl': '/platform/healthManage',
              'id': 56,
              'child': []
            }
          ]
        },
        // {
        //   'desction': null,
        //   'name': '新闻资讯',
        //   'pathUrl': '/news?type=0',
        //   'id': 33,
        //   'child': [
        //     {
        //       'desction': null,
        //       'name': '行业动态',
        //       'pathUrl': '/news?type=0',
        //       'id': 34,
        //       'child': []
        //     },
        //     {
        //       'desction': null,
        //       'name': '公司新闻',
        //       'pathUrl': '/news?type=1',
        //       'id': 35,
        //       'child': []
        //     },
        //     {
        //       'desction': null,
        //       'name': '观点分享',
        //       'pathUrl': '/news?type=3',
        //       'id': 37,
        //       'child': []
        //     }
        //   ]
        // },
        {
          'desction': null,
          'name': '关于中嘉',
          'pathUrl': '/about',
          'id': 38,
          'child': [
            {
              'desction': null,
              'name': '公司介绍',
              'pathUrl': '/about/introduce',
              'id': 39,
              'child': []
            },
            {
              'desction': null,
              'name': '招贤纳士',
              'pathUrl': '/about/recruitment',
              'id': 40,
              'child': []
            },
            {
              'desction': null,
              'name': '联系我们',
              'pathUrl': '/about/connect',
              'id': 41,
              'child': []
            }
          ]
        },
        {
          'desction': null,
          'name': '生态合作',
          'pathUrl': '/cooperation',
          'id': 44,
          'child': []
        }
      ];
      const items = data;
      this.$store.commit('setNavIds', { ...items[3] });
      // 遍历所有菜单项及子菜单项初始化 showSubmenu 属性
      items.forEach(item => {
        this.$set(item, 'showSubmenu', false);
        if (item.child && item.child.length > 0) {
          item.child.forEach(child => {
            this.$set(child, 'showSubmenu', false);
            // 如果子菜单项也有子菜单，进行初始化
            if (child.child && child.child.length > 0) {
              child.child.forEach(sub => {
                this.$set(sub, 'showSubmenu', false);
              });
            }
          });
        }
      });
      return items;
    },
    isQueryParamsMatch(pathUrl) {
      if (!pathUrl.includes('?')) return true;
      const queryParams = Object.fromEntries(new URLSearchParams(pathUrl.split('?')[1]));
      return Object.entries(queryParams).every(([key, value]) => this.$route.query[key] === value);
    },
    isActiveMainItem(item) {
      if (this.$route.path === item.pathUrl) return true;
      const checkActive = (menu) => {
        // 检查当前项（去除可能的查询参数）和查询参数匹配
        if (this.$route.path === menu.pathUrl.split('?')[0] && this.isQueryParamsMatch(menu.pathUrl)) {
          return true;
        }
        // 如果有子菜单，递归检查
        return menu.child && menu.child.some(child => checkActive(child));
      };

      // 遍历一级菜单的所有子菜单项进行判断
      return item.child && item.child.some(child => checkActive(child));
    },
    isActiveSubItem(item) {
      if (this.$route.path === item.pathUrl.split('?')[0] && this.isQueryParamsMatch(item.pathUrl)) {
        return true;
      }
      // 如果存在下一级菜单，递归检查
      return item.child && item.child.some(child => this.isActiveSubItem(child));
    },
    isActiveChildSubItem(item) {
      console.log(this.$route.path, item.pathUrl, 8999);
      return this.$route.path === item.pathUrl.split('?')[0];
    },
    navigate(item) {
      if (!item.child || item.child.length === 0 || item.name === '新闻资讯') {
        this.$router.push(item.pathUrl);
      }
    },
    showSubmenu(item) {
      item.showSubmenu = true;
    },
    hideSubmenu(item) {
      item.showSubmenu = false;
    }
  }
};
</script>

<style scoped lang="scss">
.top-nav {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  height: 84px;
  .logo {
    width: 100px;
    margin: 0 156px 0 90px;
    img {
      width: 100%;
      z-index: 1000;
    }
  }

  .nav-list {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;

    .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 40px;
      white-space: nowrap;
      &:hover,
      &.active {
        position: relative;
        .nav-link {
          color: #fff;
        }
      }
      &:hover {
        .nav-link {
          .arrow {
            transform: rotate(180deg);
          }
        }
        .submenu {
          transform: scale(1) translateX(-50%);
        }
      }
      &.active {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: #fff;
        }
      }

      .nav-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        color: rgba(255, 255, 255,.7);
        font-weight: 600;
        .arrow {
          margin-left: 5px;
          width: 8px;
          height: 8px;
          transition: all 0.2s;
        }
      }

      .submenu {
        position: absolute;
        top: 100%;
        left: 50%;
        list-style: none;
        padding: 10px 0;
        margin: 0;
        background-color: #fff;
        box-shadow: 0px 0px 4px 0px rgba(227,227,227,0.50);
        min-width: 100%;
        transition: all 0.1s ease;
        transform: scale(1, 0) translateX(-50%);
        transform-origin: top;
        .submenu-item {
          position: relative;
          height: 68px;
          line-height: 68px;
          padding: 0 38px;
          font-size: 16px;
          border-radius: 8px;
          margin-bottom: 5px;
          .submenu-link {
            cursor: pointer;
            display: flex;
            align-items: center;
            line-height: 68px;
            white-space: nowrap;
            color: #0b1d30;
          }
          &:hover,
          &.active {
            .submenu-link {
              color: #2469f1;
            }
          }
          /* 样式用于右侧二级子菜单 */
          .arrow-right {
            margin-left: 5px;
            width: 8px;
            height: 8px;
            transition: all 0.2s;
          }
          .submenu-child {
            position: absolute;
            top: -10px;
            left: 100%;
            list-style: none;
            margin: 0;
            background-color: #fff;
            box-shadow: 0px 0px 4px rgba(227,227,227,0.50);
            min-width: 180px;
            transition: all 0.1s ease;
            z-index: 10;
            text-align: center;
          }
          .submenu-child-item {
            line-height: 56px;
            background: #eaf0f7;
            cursor: pointer;
            &:hover {
              color: #2469f1;
            }
            &.active {
              color: #2469f1;
            }
          }
        }
      }

      &:hover .submenu,
      & .submenu[v-show="true"] {
        display: block;
      }
    }
  }
  .call {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    position: absolute;
    right: -100px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.nav-item:hover .nav-link .arrow,
.nav-item.active .nav-link .arrow {
  content: url('../../assets/img/arrow-down-blue.png');
}
</style>

