<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'App',
  components: { },
  data() {
    return {
    };
  },
  mounted() {
    this.setHtmlFontSize();
    if (window.addEventListener) {
      window.addEventListener('resize', () => {
        this.setHtmlFontSize();
      }, false);
    }
  },
  methods: {
    setHtmlFontSize() {
      let deviceWidth = document.documentElement.clientWidth;
      if (deviceWidth < 1200) {
        deviceWidth = 1200;
      }
      document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 19.2 + 'px !important';
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: 'PingFang', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2D2F39;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2D2F39;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
