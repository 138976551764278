<template>
  <div class="bottom">
    <div class="ship">
      <div class="ship-content">
        <div class="ship-text">
          <span>合作</span>
          <span>开放</span>
          <span>共赢</span>
        </div>
        <div class="ship-img">
          <img class="img" src="@/assets/img/ship.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="center">
        <div class="column">
          <div class="title">关于中嘉</div>
          <div class="column-item click-title" @click="$router.push('/about/introduce?id=39')">公司介绍</div>
          <div class="column-item click-title" @click="$router.push('/about/recruitment?id=40')">招贤纳士</div>
        </div>
        <!-- <div class="column">
          <div class="title">新闻动态</div>
          <div class="column-item click-title" @click="$router.push('/news?type=1&id=34')">行业观察</div>
          <div class="column-item click-title" @click="$router.push('/news?type=2&id=35')">公司新闻</div>
          <div class="column-item click-title" @click="$router.push('/news?type=3&id=37')">嘉服专栏</div>

        </div> -->
        <div class="column">
          <div class="title">联系方式</div>
          <div class="column-item">yannncn@hotmail.com</div>
          <div class="column-item">浙江省杭州市萧山区杭州湾信息港E座2幢902</div>
          <div class="column-item">技术支持与服务工单</div>
        </div>
        <div class="column">
          <div class="title">关注或联系我们</div>
          <div class="column-item">关注中嘉数科公众号</div>
          <div class="column-item">
            <img class="qrCode" src="../../assets/home/qrCode.jpg" alt="">
          </div>
          <div class="column-item phone">咨询热线：0571—82650015</div>
        </div>
      </div>
    </div>
    <div class="bottom-line" />
    <div class="bottom-bottom">
      <span class="fastLink">快速链接：
        <!-- <a href="http://www.ytsgkjt.com.cn/" target="_blank">未来城市在线游览 </a> -->
        <a href="http://www.ytsgkjt.com.cn/" target="_blank">鹰潭国控集团</a>
      </span>
      <span>{{ objData.copyright }}</span>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902003127">
        <!-- <img class="police-icon" src="@/assets/img/police-icon.png" alt=""> -->
        <span>浙公网安备 33010902003127号</span>
      </a>
      <a href="https://beian.miit.gov.cn/" target="_blank">{{ objData.record }}</a>
      <span>技术支持：中嘉数科</span>
    </div>
  </div>
</template>
<script>
// 接口
import { getConfig } from '@/service/api';
export default {
  data() {
    return {
      objData: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await getConfig();
      if (res.code === 200) {
        this.objData = res.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom {
  z-index: 100;
  width: 100%;
  // height: 320px;
  background: #f5f7fa;
  // font-family: 'pingfang-Regular';
  .ship {
    width: 100%;
    height: 120px;
    background: linear-gradient(270deg,#38b9f7, #2767ff);
    .ship-content {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      position: relative;
      align-items: center;
      padding-left: 42px;
      .ship-text {
        font-size: 24px;
        color: #fff;
        font-weight: 500;
        span {
          margin-right: 2px;
        }
      }
      .ship-img {
        position: absolute;
        right: -98px;
        bottom: 0;
        width: 320px;
        height: 220px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .bottom-box {
    width: 1200px;
    // height: 281px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    padding: 60px 0 40px;
    .left {
      height: 152px;
      padding-right: 70px;
      border-right: 1px solid #243444;
      margin-right: 54px;
      .logo {
        width: 113px;
        height: 33px;
        margin-bottom: 16px;
      }
      .phone {
        width: 149px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #30a5ff;
        font-size: 14px;
        font-weight: 600;
        color: #666666;
      }
    }
    .center {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .column {
        .title {
          height: 20px;
          line-height: 20px;
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #333333;
          margin-bottom: 20px;
          // font-family: 'pingfang-Medium';
        }
        .click-title {
          cursor: pointer;
          &:hover {
            color: #2469f1;
          }
        }
        .column-item {
          // opacity: 0.6;
          min-height: 24px;
          line-height: 24px;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #666666;
          margin-bottom: 4px;
          .qrCode {
            width: 89px;
            height: 92px;
            margin: 4px 0 22px 0;
          }
          &.phone {
            width: 200px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #0052d9;
            font-size: 14px;
            // font-family: 'PingFang-Medium'!important;
            color: #ffffff;
          }
        }
      }
    }
    .right {
      margin-left: 85px;
      width: 126px;
      .title {
        font-size: 14px;
        font-weight: 300;
        // text-align: center;
        color: #333333;
        margin-bottom: 24px;
        margin-left: -6px;
      }
      .qrCode {
        width: 113px;
        height: 113px;
      }
    }
  }
  .bottom-line {
    width: 1201px;
    height: 1px;
    margin: 0 auto;
    border: 1px solid #dbdbdb;
  }
  .bottom-bottom {
    height: 100px;
    line-height: 68px;
    font-size: 14px;
    width: 1200px;
    margin: 0 auto;
    color: #666666;
    display: flex;
    justify-content: space-between;
    .police-icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    a {
      color: #666666;
    }
    .fastLink {
      a {
        color: #666666;
        margin-left: 10px;
      }
    }
  }
}
</style>
